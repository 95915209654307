import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Card from '../Card/Card.jsx';

import { addMarshall, removeMarshall, getRaces } from '../../actions/user';
import MarshallControl from './MarshallControl/MarshallControl.jsx';

class Schedule extends Component {
  componentDidMount() {
    this.props.dispatch(getRaces());
  }
  render() {
    const { races, auth } = this.props || [];

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
                title="2020 G-Tour Schedule"
                category="Select event for more details."
                tableFullWidth
                content={
                  <Table striped hover responsive>
                    <thead>
                      <tr>
                        {['Date', 'Course', 'Type', 'Race Notes', 'Marshalls'].map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {races.map((race, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <Link to={`races/${race._id}`}>
                                {moment(race.date)
                                  .add(18, 'h')
                                  .format('LL')}
                              </Link>
                            </td>
                            <td>{race.course.name}</td>
                            <td>{race.course.type}</td>
                            <td>{race.notes}</td>
                            <td>
                              <MarshallControl
                                addMarshall={(raceID, userID) => {
                                  this.props.dispatch(addMarshall(raceID, userID));
                                }}
                                removeMarshall={(raceID, userID) => {
                                  this.props.dispatch(removeMarshall(raceID, userID));
                                }}
                                key={key}
                                race={race}
                                isAuthenticated={auth.isAuthenticated}
                                user={auth.user}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { races } = state.races;
  const auth = state.auth;
  return { races, auth };
};
export default connect(mapStateToProps)(Schedule);
