import {
  ADD_TEAM,
  GET_TEAMS,
  REMOVE_TEAM,
  ADD_COURSE,
  GET_COURSES,
  REMOVE_COURSE,
  ADD_RACE,
  GET_RACES,
  REMOVE_RACE,
} from '../actions/types';

const initialState = { riders: [], teams: [], races: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM:
      return {
        ...state,
        teams: [...state.teams, action.payload],
      };
    case GET_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };
    case REMOVE_TEAM:
      return {
        ...state,
        teams: state.teams.filter(team => team._id !== action.payload),
      };

    case ADD_COURSE:
      return {
        ...state,
        courses: [...state.courses, action.payload],
      };
    case GET_COURSES:
      return {
        ...state,
        courses: action.payload,
      };
    case REMOVE_COURSE:
      return {
        ...state,
        courses: state.courses.filter(course => course._id !== action.payload),
      };

    case ADD_RACE:
      return {
        ...state,
        races: [...state.races, action.payload],
      };
    case GET_RACES:
      return {
        ...state,
        races: action.payload,
      };
    case REMOVE_RACE:
      return {
        ...state,
        races: state.races.filter(race => race._id !== action.payload),
      };

    default:
      return state;
  }
};
