import React from 'react';
import { Button } from 'react-bootstrap';
import swal from '@sweetalert/with-react';
import moment from 'moment';

export default function MarshallControl(props) {
  // Build up Marshall Controlls
  const { race, user, isAuthenticated, addMarshall, removeMarshall } = props;
  const { course } = race;
  let controlls = [];

  const buildAddMarshallButtons = key => {
    const alreadySignedUp = race.marshalls.some(marshall => {
      return marshall._id === user.id;
    });
    const disabledMessage = alreadySignedUp
      ? 'You are already signed up to Marshall this race'
      : 'Please sign in to secure a marshalling date.';

    // check for authenticated and not already assigned to marshall
    return isAuthenticated && !alreadySignedUp ? (
      <Button
        key={key}
        className="btn-round btn-social btn-google"
        style={{ marginRight: '10px' }}
        onClick={() => {
          swal({
            title: 'Marshall Sign-Up',
            text: `You are agreeing to marshall the race on ${moment(race.date)
              .add(18, 'h')
              .format('LL')} at ${course.name}.`,
            icon: 'info',
            buttons: {
              cancel: {
                text: 'Cancel',
                value: null,
                visible: true,
              },
              confirm: {
                text: 'I Agree',
                value: true,
              },
            },
          }).then(confirmed => {
            if (confirmed) addMarshall(race._id, user.id);
          });
        }}
      >
        <i className="fa fa-plus" />
      </Button>
    ) : (
      <Button
        key={key}
        className="btn-round btn-social"
        style={{ marginRight: '10px' }}
        onClick={() => {
          swal({ title: 'Unable to Add Marshall', text: disabledMessage, icon: 'error' });
        }}
      >
        <i className="fa fa-plus"></i>
      </Button>
    );
  };

  // OnClick behavior for already assigned marshall slots
  const onMarshallClick = (race, marshallID) => {
    // If the logged in user is the marshall, let them undo their assignment
    if (marshallID === user.id) {
      swal({
        title: 'Remove Marshalling Assignment',
        text: `You are removing your assignment to marshall the race on ${moment(race.date)
          .add(18, 'h')
          .format('LL')} at ${
          course.name
        }.  If this put's you below the required 2 marshalling assignments you will be removed from the Active Riders List!`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
          },
          confirm: {
            text: 'I Agree',
            value: true,
          },
        },
      }).then(confirmed => {
        if (confirmed) removeMarshall(race._id, user.id);
      });
    }
  };

  // Create the Marshall Controlls, one for each Marshall available for the course
  for (let m = 0; m < course.marshalls; m++) {
    // Is there a marshall for this slot?
    const item =
      race.marshalls.length > m ? (
        <img
          key={m}
          className="avatar img-circle"
          src={race.marshalls[m].avatar}
          title={`${race.marshalls[m].firstName} ${race.marshalls[m].lastName}`}
          alt="Avatar"
          style={{ marginRight: '10px' }}
          onClick={() => {
            onMarshallClick(race, race.marshalls[m]._id);
          }}
        />
      ) : (
        buildAddMarshallButtons(m)
      );
    controlls.push(item);
  }
  return controlls;
}
