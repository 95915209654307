import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default class CourseList extends Component {
  render() {
    const headers = ['Course Name', 'Course Type', 'Number of Required Marshalls'];
    const courses = this.props.courses || [];
    const CourseTable = (
      <div>
        <Table striped hover responsive className="smushed">
          <thead>
            <tr>
              {headers.map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {courses.map((course, key) => {
              return (
                <tr key={key}>
                  <td>{course.name}</td>
                  <td>{course.type}</td>
                  <td>{course.marshalls}</td>
                  <td>
                    <Button
                      className="pull-right btn-simple"
                      bsStyle="danger"
                      onClick={() => {
                        this.props.removeCourse(course);
                      }}
                    >
                      <i className="fa fa-trash fa-lg"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
    return <Card title="Manage Courses" content={CourseTable} />;
  }
}
