import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';

import { logOutUser } from '../../actions/auth';

class HeaderLinks extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Nav pullRight>
          {!this.props.auth.isAuthenticated ? (
            <NavItem eventKey={1} href="/auth/login">
              Sign In
            </NavItem>
          ) : null}
          {!this.props.auth.isAuthenticated ? (
            <NavItem eventKey={2} href="/auth/register">
              Register
            </NavItem>
          ) : null}
          {this.props.auth.isAuthenticated ? (
            <NavDropdown
              eventKey={3}
              title={
                <div>
                  <img
                    className="avatar img-circle"
                    src={this.props.auth.user.avatar}
                    alt="Profile"
                  />
                  <p className="hidden-md hidden-lg">
                    More
                    <b className="caret" />
                  </p>
                </div>
              }
              noCaret
              id="basic-nav-dropdown-3"
              bsClass="dropdown-with-icons dropdown"
            >
              {/* <MenuItem eventKey={3.1}>
                <i className="pe-7s-tools" /> Settings
              </MenuItem>
              <MenuItem divider /> */}

              <MenuItem eventKey={3.2} onClick={() => this.props.logOutUser()}>
                <div className="text-danger">
                  <i className="pe-7s-close-circle" /> Log out
                </div>
              </MenuItem>
            </NavDropdown>
          ) : null}
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logOutUser })(HeaderLinks);
