import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import RidersCard from './RidersCard/RidersCard';

export default function ApprovedRiders() {
  const [approved, setApproved] = useState([]);
  const [unapproved, setUnpproved] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('/api/users/approved').then(result => {
      setLoading(false);
      setApproved(result.data.approved.sort(sortLastName));
      setUnpproved(result.data.unapproved.sort(sortLastName));
    });
  }, []);

  const sortLastName = (a, b) => {
    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
    return 0;
  };
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>
          <Card
            title={
              <div>
                <h2>
                  <span style-="color: Black">
                    <i className="fa fa-users" />
                  </span>{' '}
                  GVCC Approved Riders List
                </h2>
                <h4>Three Requirements for being an Approved Rider...</h4>
                <ul>
                  <li>
                    <Link to="auth/register">Register an account on the G-Tour Site</Link>
                  </li>
                  <li>
                    <Link to="schedule">Sign up to marshall 2 G-Tour races or 1 USAC Race.</Link>
                  </li>
                  <li>Return a signed waiver to membership@gvccracing.com</li>
                </ul>
              </div>
            }
            content={
              <>
                <Row>
                  <Col md={6}></Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <RidersCard riders={approved} approved={true} loading={loading}></RidersCard>
                  </Col>
                  <Col md={6}>
                    <RidersCard riders={unapproved} loading loading={loading}></RidersCard>
                  </Col>
                </Row>
              </>
            }
          />
        </Row>
      </Grid>
    </div>
  );
}
