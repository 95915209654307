import axios from 'axios';
import {
  GET_TEAMS,
  ADD_TEAM,
  REMOVE_TEAM,
  GET_COURSES,
  ADD_COURSE,
  REMOVE_COURSE,
  ADD_RACE,
  REMOVE_RACE,
} from './types';

//Get List of Teams
export const getTeams = () => dispatch => {
  axios.get('/api/teams').then(result => {
    dispatch({
      type: GET_TEAMS,
      payload: result.data,
    });
  });
};

//Add a new Team
export const addTeam = teamData => dispatch => {
  axios.post('/api/teams', teamData).then(result => {
    if (result.status === 200) {
      dispatch({
        type: ADD_TEAM,
        payload: result.data,
      });
    }
  });
};

//Remove a Team
export const removeTeam = teamData => dispatch => {
  axios.delete(`/api/teams/${teamData._id}`).then(result => {
    if (result.status === 200) {
      dispatch({ type: REMOVE_TEAM, payload: teamData._id });
    }
  });
};

//Get List of Courses
export const getCourses = () => dispatch => {
  axios.get('/api/courses').then(result => {
    dispatch({
      type: GET_COURSES,
      payload: result.data,
    });
  });
};

//Add a new Course
export const addCourse = courseData => dispatch => {
  axios.post('/api/courses', courseData).then(result => {
    if (result.status === 200) {
      dispatch({
        type: ADD_COURSE,
        payload: result.data,
      });
    }
  });
};

//Remove a Course
export const removeCourse = courseData => dispatch => {
  axios.delete(`/api/courses/${courseData._id}`).then(result => {
    if (result.status === 200) {
      dispatch({ type: REMOVE_COURSE, payload: courseData._id });
    }
  });
};

//Add a new Race
export const addRace = raceData => dispatch => {
  axios.post('/api/races', raceData).then(result => {
    if (result.status === 200) {
      dispatch({
        type: ADD_RACE,
        payload: result.data,
      });
    }
  });
};

//Remove a Race
export const removeRace = raceData => dispatch => {
  axios.delete(`/api/races/${raceData._id}`).then(result => {
    if (result.status === 200) {
      dispatch({ type: REMOVE_RACE, payload: raceData._id });
    }
  });
};
