import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default function RaceList(props) {
  const [races, setRaces] = useState([]);
  useEffect(() => {
    axios.get('/api/races').then(result => {
      setRaces(result.data);
    });
  }, []);

  const removeRace = race => {
    setRaces(races.filter(r => r._id !== race._id));
  };

  const headers = ['Date', 'Course Name', 'Type', 'Required Marshalls', 'Marshalls', 'Race Notes'];
  const RacesTable = (
    <div>
      <Table striped hover responsive className="smushed">
        <thead>
          <tr>
            {headers.map((prop, key) => {
              return <th key={key}>{prop}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {races.map((race, key) => {
            const { date, course } = race;
            return (
              <tr key={key}>
                <td>
                  {moment(date)
                    .add(18, 'h')
                    .format('LL')}
                </td>
                <td>{course.name}</td>
                <td>{course.type}</td>
                <td>{course.marshalls}</td>
                <td>{race.marshalls.length}</td>
                <td>{race.notes}</td>
                <td>
                  <Button
                    className="pull-right btn-simple"
                    bsStyle="danger"
                    onClick={() => {
                      props.removeRace(race);
                      removeRace(race);
                    }}
                  >
                    <i className="fa fa-trash fa-lg"></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
  return <Card title="Races" content={RacesTable} />;
}
