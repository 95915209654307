import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

import AddRace from './AddRace';
import RaceList from './RaceList';

import { getRaces } from '../../actions/user';
import { addRace, removeRace, getCourses } from '../../actions/admin';

class ManageSchedule extends Component {
  componentDidMount() {
    this.props.dispatch(getRaces());
    this.props.dispatch(getCourses());
  }

  render() {
    let { races } = this.props || [];
    const { courses } = this.props || [];
    const title = (
      <div>
        <h2>
          <span style-="color: Black">
            <i className="fa fa-calendar" />
          </span>{' '}
          Manage Schedule
        </h2>
      </div>
    );

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
                title={title}
                category="View existing races, and create new races."
                content={
                  <Row>
                    <Col md={8}>
                      <RaceList
                        races={races}
                        removeRace={race => this.props.dispatch(removeRace(race))}
                      />
                    </Col>
                    <Col md={4}>
                      <AddRace
                        addRace={race => this.props.dispatch(addRace(race))}
                        courses={courses}
                      ></AddRace>
                    </Col>
                  </Row>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const admin = state.admin;
  return admin;
};
export default connect(mapStateToProps)(ManageSchedule);
