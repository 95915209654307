import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Select from 'react-select';

import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default class AddCourse extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', type: '', marshalls: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddCourse = this.handleAddCourse.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAddCourse(e) {
    e.preventDefault();
    this.props.addCourse({
      name: this.state.name,
      type: this.state.type.value,
      marshalls: this.state.marshalls.value,
    });
    this.setState({ name: '', type: '', marshalls: '' });
  }

  render() {
    const courseOptions = [
      { value: 'Road Race', label: 'Road Race' },
      { value: 'Time Trial', label: 'Time Trial' },
    ];
    const CourseForm = (
      <div>
        <form onSubmit={this.handleAddCourse}>
          <FormGroup>
            <ControlLabel>Course Name</ControlLabel>
            <FormControl
              placeholder="Parish Hill"
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Course Type</ControlLabel>
            <Select
              name="type"
              options={courseOptions}
              value={this.state.type}
              onChange={value => {
                this.setState({ type: value });
              }}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Required Marshalls</ControlLabel>
            <Select
              name="marshalls"
              options={[
                { value: 0, label: '0' },
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
              ]}
              value={this.state.marshalls}
              onChange={value => {
                this.setState({ marshalls: value });
              }}
            />
          </FormGroup>
          <Button type="submit" bsStyle="primary" fill>
            <i className="fa fa-plus" /> {' Add Course'}
          </Button>
        </form>
      </div>
    );
    return <Card title="Create new Course" content={CourseForm} />;
  }
}
