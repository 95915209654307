import { connect } from 'react-redux';
import Container from '../components/Container';

const mapStateToProps = state => ({
  auth: state.auth,
  admin: state.admin,
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
