import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';

import { registerUser } from '../../actions/auth';

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      errors: {},
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false,
      });
    }
  }

  handleRegister(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const user = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
    };

    this.props.registerUser(user, this.props.history);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: {} });
  }

  handleError(error) {
    if (error) {
      return 'error';
    }
  }

  buildHelp(error) {
    if (error) {
      return <HelpBlock>{error}</HelpBlock>;
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
              <h2>GVCC | G-Tour</h2>
              <h4>Register to manage your GVCC Profile, Teams, Results, and Marshalling Duty.</h4>
              <hr />
            </div>
          </Col>
          <Col md={4} mdOffset={4}>
            <form onSubmit={this.handleRegister}>
              <Card
                plain
                content={
                  <div>
                    <FormGroup validationState={this.handleError(this.state.errors.name)}>
                      <FormControl
                        type="text"
                        name="firstName"
                        placeholder="Your First Name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.name ? this.buildHelp(this.state.errors.name) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(this.state.errors.name)}>
                      <FormControl
                        type="text"
                        name="lastName"
                        placeholder="Your Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.name ? this.buildHelp(this.state.errors.name) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(this.state.errors.email)}>
                      <FormControl
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.email ? this.buildHelp(this.state.errors.email) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(this.state.errors.password)}>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.password ? this.buildHelp(this.state.errors.password) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(this.state.errors.password2)}>
                      <FormControl
                        type="password"
                        name="password2"
                        placeholder="Password Confirmation"
                        value={this.state.password2}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.password2
                        ? this.buildHelp(this.state.errors.password2)
                        : ''}
                    </FormGroup>
                  </div>
                }
                ftTextCenter
                legend={
                  <Button type="submit" disabled={isLoading} wd fill neutral>
                    {isLoading ? 'Creating Account...' : 'Create Account'}
                  </Button>
                }
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(RegisterPage));
