import React, { useState, useEffect } from 'react';
import { Grid, Row, Table } from 'react-bootstrap';
import Switch from 'react-bootstrap-switch';
import Loader from 'react-loader-spinner';

import axios from 'axios';
import Card from '../Card/Card';

export default function ManageRiders() {
  const [riders, setRiders] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    axios.get('/api/users/approved').then(result => {
      setLoading(false);
      setRiders(result.data.unapproved.concat(result.data.approved).sort(sortLastName));
    });
  }, []);

  const sortLastName = (a, b) => {
    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1;
    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1;
    return 0;
  };

  const handleSwitch = (id, waiver) => {
    axios.post(`/api/users/${id}/${waiver}`);
  };

  const ridersTable = riders => (
    <Table striped hover responsive>
      <thead>
        <tr>
          {['Name', 'Membership', 'Marshalling', 'Signed Waiver', 'Service Waiver'].map(
            (prop, key) => {
              return <th key={key}>{prop}</th>;
            }
          )}
        </tr>
      </thead>
      <tbody>
        {riders.map((rider, key) => {
          return (
            <tr key={key}>
              <td>{`${rider.firstName} ${rider.lastName}`}</td>
              <td>{rider.membership}</td>
              <td>{rider.marshallAssignments.length}</td>
              <td>
                <Switch
                  onChange={() => {
                    rider.signedWaiver = !rider.signedWaiver;
                    handleSwitch(rider._id, 'signedWaiver');
                  }}
                  defaultValue={rider.signedWaiver}
                  onText="Yes"
                  offText="No"
                />
              </td>
              <td>
                <Switch
                  onChange={() => {
                    rider.signedWaiver = !rider.signedWaiver;
                    handleSwitch(rider._id, 'serviceWaiver');
                  }}
                  defaultValue={rider.serviceWaiver}
                  onText="Yes"
                  offText="No"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

  const card = loading ? (
    <Card
      title={
        <div>
          <h2>
            <span style-="color: Black">
              <i className="fa fa-users" />
            </span>{' '}
            Manage Riders
          </h2>
        </div>
      }
      content={<Loader type="ThreeDots" color="#666" height={100} width={100} />}
      stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
      ctTextCenter
    />
  ) : (
    <Card
      title={
        <div>
          <h2>
            <span style-="color: Black">
              <i className="fa fa-users" />
            </span>{' '}
            Manage Riders
          </h2>
        </div>
      }
      content={ridersTable(riders)}
      stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
    />
  );
  return (
    <div className="main-content">
      <Grid fluid>
        <Row>{card}</Row>
      </Grid>
    </div>
  );
}
