import React from 'react';
import { Table } from 'react-bootstrap';
import Loader from 'react-loader-spinner';

import Card from '../../Card/Card';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function RidersCard(props) {
  const { approved, riders, loading } = props;
  const goodStyle = {
    color: '#1d62f0',
  };
  const badStyle = {
    color: '#fb404b',
  };
  return loading ? (
    <Card
      title={
        <div>
          <h2>
            <span style-="color: Black">
              {approved ? <i className="fa fa-thumbs-up" /> : <i className="fa fa-thumbs-down"></i>}
            </span>{' '}
            {approved ? 'Approved Riders' : 'Unapproved Riders'}
          </h2>
        </div>
      }
      content={<Loader type="ThreeDots" color="#666" height={100} width={100} />}
      ctTextCenter
    />
  ) : (
    <Card
      title={
        <div>
          <h2>
            <span style-="color: Black">
              {approved ? <i className="fa fa-thumbs-up" /> : <i className="fa fa-thumbs-down"></i>}
            </span>{' '}
            {approved ? 'Approved Riders' : 'Unapproved Riders'}
          </h2>
        </div>
      }
      content={
        <Table striped hover responsive>
          <thead>
            <tr>
              {['Name', 'Membership', 'Marshalling', 'Signed Waiver'].map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {riders.map((rider, key) => {
              return (
                <tr key={key}>
                  <td>{`${rider.firstName} ${rider.lastName}`}</td>
                  <td>
                    {rider.membership ? (
                      <>
                        <i className="fa fa-thumbs-up" style={goodStyle} />
                        {` ${rider.membership}`}
                      </>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Sign-up on <strong>BikeReg.com</strong>
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-ban" style={badStyle}></i>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    {rider.marshallRequirement || rider.serviceWaiver ? (
                      <i className="fa fa-thumbs-up" style={goodStyle} />
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Sign-up to <strong>Marshall 2 races on the schedule.</strong>
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-ban" style={badStyle}></i>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    {rider.signedWaiver || rider.serviceWaiver ? (
                      <i className="fa fa-thumbs-up" style={goodStyle} />
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Return a <strong>signed Waiver to a GVCC Officer.</strong>
                          </Tooltip>
                        }
                      >
                        <i className="fa fa-ban" style={badStyle}></i>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      }
      stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
    />
  );
}
