import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Footer from '../components/Footer/Footer.js';
import PagesHeader from '../components/Navbars/AuthNavbar.jsx';
import bgImage from '../assets/img/bikerace.jpg';

import LoginPage from '../views/Pages/LoginPage.js';
import RegisterPage from '../views/Pages/RegisterPage.js';
import ForgotPassword from '../views/Pages/ForgotPassword.jsx';
import ResetPassword from '../views/Pages/ResetPassword';

// dinamically create pages routes
const routes = [
  {
    path: '/auth/login',
    name: 'Login Page',
    layout: '/auth',
    mini: 'LP',
    component: LoginPage,
  },
  {
    path: '/auth/register',
    name: 'Register',
    layout: '/auth',
    mini: 'RP',
    component: RegisterPage,
  },
  {
    path: '/auth/forgotPassword',
    name: 'Forgot Password Page',
    layout: '/auth',
    mini: 'FP',
    component: ForgotPassword,
  },
  {
    path: '/auth/resetPassword/:token',
    name: 'Reset Password Page',
    layout: '/auth',
    mini: 'RP',
    component: ResetPassword,
  },
];

class Pages extends Component {
  getPageClass() {
    var pageClass = '';
    switch (this.props.location.pathname) {
      case '/auth/login':
        pageClass = ' login-page';
        break;
      case '/auth/register':
        pageClass = ' register-page';
        break;
      case '/auth/forgotPassword':
        pageClass = ' login-page';
        break;
      default:
        pageClass = ' login-page';
        break;
    }
    return pageClass;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  render() {
    return (
      <div>
        <PagesHeader />
        <div className="wrapper wrapper-full-page">
          <div
            className={'full-page' + this.getPageClass()}
            data-color="black"
            data-image={bgImage}
          >
            <div className="content">
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            <Footer transparent />
            <div
              className="full-page-background"
              style={{ backgroundImage: 'url(' + bgImage + ')' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
