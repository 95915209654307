import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Row, Col, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';

import { resetPassword } from '../../actions/auth';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      password: '',
      password2: '',
      errors: {},
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false,
      });
    }
  }

  handleRegister(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });

    this.props.resetPassword(
      {
        password: this.state.password,
        password2: this.state.password2,
        token: this.props.match.params.token,
      },
      this.props.history
    );
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ errors: {} });
  }

  handleError(error) {
    if (error) {
      return 'error';
    }
  }

  buildHelp(error) {
    if (error) {
      return <HelpBlock>{error}</HelpBlock>;
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.handleRegister}>
              <Card
                textCenter
                title="Reset Password"
                content={
                  <div>
                    <FormGroup validationState={this.handleError(this.state.errors.password)}>
                      <FormControl
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.password ? this.buildHelp(this.state.errors.password) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(this.state.errors.password2)}>
                      <FormControl
                        type="password"
                        name="password2"
                        placeholder="Password Confirmation"
                        value={this.state.password2}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {this.state.errors.password2
                        ? this.buildHelp(this.state.errors.password2)
                        : ''}
                    </FormGroup>
                  </div>
                }
                ftTextCenter
                legend={
                  <Button type="submit" disabled={isLoading} wd fill bsStyle="danger">
                    {isLoading ? 'Updating Password...' : 'Update Password'}
                  </Button>
                }
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { resetPassword })(withRouter(ResetPassword));
