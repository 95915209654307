import React, { useState } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';

import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default function AddRace(props) {
  const [date, setDate] = useState('');
  const [course, setCourse] = useState('');
  const [notes, setNotes] = useState('');

  const courses = props.courses || [];
  const courseOptions = courses.map(c => {
    return { value: c._id, label: c.name };
  });

  const handleSubmit = event => {
    event.preventDefault();
    props.addRace({
      date: moment(date).format('YYYY-MM-DD'),
      course: course.value,
      notes,
    });
    setDate('');
    setCourse('');
    setNotes('');
  };

  const RaceForm = (
    <div>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <ControlLabel>Date</ControlLabel>
          <Datetime
            timeFormat={false}
            value={date}
            onChange={value => {
              setDate(value);
            }}
          />{' '}
        </FormGroup>
        <FormGroup>
          <ControlLabel>Course Name</ControlLabel>
          <Select
            placeholder=""
            value={course}
            options={courseOptions}
            onChange={value => {
              setCourse(value);
            }}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Race Notes</ControlLabel>
          <FormControl
            type="text"
            value={notes}
            placeholder="LCC Crossover"
            onChange={e => setNotes(e.target.value)}
          />
        </FormGroup>
        <Button type="submit" bsStyle="primary" fill>
          <i className="fa fa-plus" /> {' Add Race'}
        </Button>
      </form>
    </div>
  );
  return <Card title="Create new Race" content={RaceForm} />;
}
