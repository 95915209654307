import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from 'react-notification-system';

// dinamically create dashboard routes
import routes from '../../routes.js';

import Sidebar from '../Sidebar/Sidebar.js';
import Header from '../Navbars/AdminNavbar.jsx';
import Footer from '../Footer/Footer.js';

// style for notifications
import { style } from '../../variables/Variables.js';

let ps;

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
    };
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf('Win') > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === 'PUSH' &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
    }
  }
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15,
    });
  }

  render() {
    const { auth } = this.props;
    const { user } = auth;
    const filteredRoutes = routes.filter(route => {
      return route.admin === false || user.admin === true;
    });
    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar {...this.props} routes={filteredRoutes} />
        <div
          className={
            'main-panel' +
            (this.props.location.pathname === '/maps/full-screen-maps' ? ' main-panel-maps' : '')
          }
          ref="mainPanel"
        >
          <Header {...this.props} />
          <Switch>
            {filteredRoutes.map((prop, key) => {
              if (prop.collapse) {
                return prop.views.map((prop, key) => {
                  if (prop.name === 'Notifications') {
                    return (
                      <Route
                        path={prop.path}
                        key={key}
                        render={routeProps => (
                          <prop.component
                            {...routeProps}
                            handleClick={this.handleNotificationClick}
                          />
                        )}
                      />
                    );
                  } else {
                    return <Route path={prop.path} component={prop.component} key={key} />;
                  }
                });
              } else {
                if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                else return <Route path={prop.path} component={prop.component} key={key} />;
              }
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}
