import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddTeam = this.handleAddTeam.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleAddTeam(e) {
    e.preventDefault();
    this.props.addTeam({
      name: this.state.name,
    });
    this.setState({ name: '' });
  }

  render() {
    const TeamForm = (
      <div>
        <form onSubmit={this.handleAddTeam}>
          <FormGroup>
            <ControlLabel>Team Name</ControlLabel>
            <FormControl
              placeholder="Quickstep Floors"
              type="text"
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>
          <Button type="submit" bsStyle="primary" fill>
            <i className="fa fa-plus" /> {' Add Team'}
          </Button>
        </form>
      </div>
    );
    return <Card title="Create new Team" content={TeamForm} />;
  }
}
