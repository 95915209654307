import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';

export default function ForgotPasword(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => setHidden(false), 700);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    axios
      .post('/api/users/forgotPassword', { email })
      .then(response => {
        setIsLoading(false);
        props.history.push('/auth/login');
      })
      .catch(err => {
        setIsLoading(false);
      });
  };

  return (
    <Grid>
      <Row>
        <Col md={4} sm={6} mdOffset={4} smOffset={3}>
          <Card
            hidden={hidden}
            textCenter
            title="Forgot Password?"
            content={
              <div>
                <form>
                  <FormGroup>
                    <ControlLabel>Email address</ControlLabel>
                    <FormControl
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </FormGroup>
                </form>
                <small>
                  Enter your email address to revieve an email with a link to reset your password.
                </small>
              </div>
            }
            legend={
              <>
                <Button
                  type="submit"
                  disabled={isLoading}
                  bsStyle="danger"
                  onClick={handleSubmit}
                  fill
                  wd
                >
                  {isLoading ? 'Sending email...' : 'Reset Password'}
                </Button>
              </>
            }
            ftTextCenter
          />
        </Col>
      </Row>
    </Grid>
  );
}
