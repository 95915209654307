import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid, Row, Col, FormGroup, HelpBlock, ControlLabel, FormControl } from 'react-bootstrap';

import Card from '../../components/Card/Card.jsx';
import Button from '../../components/CustomButton/CustomButton.jsx';

import { loginUser } from '../../actions/auth';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,
      isLoading: false,
      email: '',
      password: '',
      errors: {},
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/');
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        isLoading: false,
      });
    }
  }

  handleLogin(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(user);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errors: {},
    });
  }

  handleError(error) {
    if (error) {
      return 'error';
    }
  }

  buildHelp(error) {
    if (error) {
      return <HelpBlock>{error}</HelpBlock>;
    }
  }

  render() {
    const { isLoading } = this.state;
    const { errors } = this.state;
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form onSubmit={this.handleLogin}>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup validationState={this.handleError(errors.email)}>
                      <ControlLabel>Email address</ControlLabel>
                      <FormControl
                        placeholder="Enter email"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {errors.email ? this.buildHelp(errors.email) : ''}
                    </FormGroup>
                    <FormGroup validationState={this.handleError(errors.password)}>
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                      />
                      <FormControl.Feedback />
                      {errors.password ? this.buildHelp(errors.password) : ''}
                    </FormGroup>
                  </div>
                }
                legend={
                  <>
                    <Button type="submit" disabled={isLoading} bsStyle="info" fill wd>
                      {isLoading ? 'Logging in...' : 'Login'}
                    </Button>

                    <Link to="/auth/forgotPassword">
                      <p style={{ paddingTop: '16px', color: '#fb404b' }}>Forgot Password?</p>
                    </Link>
                  </>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  { loginUser }
)(LoginPage);
