import { connect } from 'react-redux';
import Auth from '../layouts/Auth.js';

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
