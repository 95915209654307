import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

import AddTeam from './AddTeam';
import TeamList from './TeamList';

import { getTeams, addTeam, removeTeam } from '../../actions/admin';

class ManageTeams extends Component {
  componentDidMount() {
    this.props.dispatch(getTeams());
  }

  render() {
    const { teams } = this.props || [];
    const title = (
      <div>
        <h2>
          <span style-="color: Black">
            <i className="fa fa-users" />
          </span>{' '}
          Manage Teams
        </h2>
      </div>
    );

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
                title={title}
                category="View existing Teams, and create new Teams."
                content={
                  <Row>
                    <Col md={8}>
                      <TeamList
                        teams={teams}
                        removeTeam={team => this.props.dispatch(removeTeam(team))}
                      />
                    </Col>
                    <Col md={4}>
                      <AddTeam
                        addTeam={Team => {
                          this.props.dispatch(addTeam(Team));
                        }}
                      />
                    </Col>
                  </Row>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const admin = state.admin;
  return admin;
};
export default connect(mapStateToProps)(ManageTeams);
