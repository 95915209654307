import axios from 'axios';
import { GET_RACES, ADD_MARSHALL, REMOVE_MARSHALL } from './types';

//Get List of Races
export const getRaces = () => dispatch => {
  axios.get('/api/races').then(result => {
    dispatch({
      type: GET_RACES,
      payload: result.data,
    });
  });
};

// Add Marshall to Race
export const addMarshall = (raceID, userID) => dispatch => {
  axios.post(`/api/races/${raceID}/marshalls/${userID}`).then(result => {
    if (result.status === 200) {
      dispatch({
        type: ADD_MARSHALL,
        payload: result.data,
      });
    }
  });
};

// Remove Marshall from Race
export const removeMarshall = (raceID, userID) => dispatch => {
  axios.delete(`/api/races/${raceID}/marshalls/${userID}`).then(result => {
    if (result.status === 200) {
      dispatch({
        type: REMOVE_MARSHALL,
        payload: result.data.race,
      });
    }
  });
};
