import { GET_RACES, ADD_MARSHALL, REMOVE_MARSHALL } from '../actions/types';
const initialState = { races: [] };
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RACES:
      return {
        ...state,
        races: action.payload,
      };
    case ADD_MARSHALL:
      const racesAddMarshall = state.races.map(race => {
        if (race._id !== action.payload._id) return race;
        return action.payload;
      });
      return {
        ...state,
        races: racesAddMarshall,
      };
    case REMOVE_MARSHALL:
      const raceRemoveMarshall = state.races.map(race => {
        if (race._id !== action.payload._id) return race;
        return action.payload;
      });
      return { ...state, races: raceRemoveMarshall };
    default:
      return state;
  }
};
