import React, { Component } from 'react';
import { Table, Grid, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

class Race extends Component {
  constructor(props) {
    super(props);
    this.state = {
      race: {
        th: ['Place', 'Rider', 'Team', 'Points'],
        td: [
          ['Primoz Roglic', 'Team Jumbo-Visma', '10'],
          ['Julian Alaphilippe', 'Quick Step', '8'],
          ['Jakob Fuglsang', 'Astana Pro Team', '7'],
          ['Alejandro Valverde', 'Movistar Team', '6'],
          ['Greg Van Avermaet', 'CCC Team', '5'],
          ['Elia Viviani', 'Quick Step', '4'],
          ['Pascal Ackermann', 'Bora Hansgrohe', '3'],
          ['Egan Bernal', 'Team Ineos', '2'],
          ['Alexander Kristoff', 'Team UAE', '1'],
          ['Peter Sagan', 'Bora Hansgrohe', '1'],
        ],
      },
      prime1: {
        th: ['Place', 'Rider', 'Team', 'Points'],
        td: [
          ['Egan Bernal', 'Team Ineos', '3'],
          ['Alexander Kristoff', 'Team UAE', '2'],
          ['Peter Sagan', 'Bora Hansgrohe', '1'],
        ],
      },
      prime2: {
        th: ['Place', 'Rider', 'Team', 'Points'],
        td: [
          ['Elia Viviani', 'Quick Step', '3'],
          ['Pascal Ackermann', 'Bora Hansgrohe', '2'],
          ['Egan Bernal', 'Team Ineos', '1'],
        ],
      },
    };
  }
  render() {
    const title = (
      <div>
        <h2>
          <span style-="color: Black">
            <i className="fa fa-calendar-o" />
          </span>{' '}
          April 7th, 2019
        </h2>
      </div>
    );

    const category = <h3>Mendon, Road Race</h3>;
    const stats = <small>Updated on July 24th, 2019</small>;
    const aContent = (
      <div>
        <Table striped hover responsive>
          <thead>
            <tr>
              {this.state.race.th.map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.race.td.map((prop, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{prop[0]}</td>
                  <td>{prop[1]}</td>
                  <td>{prop[2]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <h5 className="title">Prime #1 Results</h5>
        <Table striped hover responsive>
          <thead>
            <tr>
              {this.state.prime1.th.map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.prime1.td.map((prop, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{prop[0]}</td>
                  <td>{prop[1]}</td>
                  <td>{prop[2]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <h5 className="title">Prime #2 Results</h5>
        <Table striped hover responsive>
          <thead>
            <tr>
              {this.state.prime2.th.map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.prime2.td.map((prop, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{prop[0]}</td>
                  <td>{prop[1]}</td>
                  <td>{prop[2]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
    const content = (
      <Row>
        <Col md={6}>
          <Card title="A-Group Race Results" content={aContent} />
        </Col>

        <Col md={6}>
          <Card title="B-Group Race Results" content={aContent}></Card>
        </Col>
      </Row>
    );

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card stats={stats} title={title} category={category} content={content}></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Race;
