import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

import AddCourse from './AddCourse';
import CourseList from './CourseList';

import { getCourses, addCourse, removeCourse } from '../../actions/admin';

class ManageCourses extends Component {
  componentDidMount() {
    this.props.dispatch(getCourses());
  }

  render() {
    const { courses } = this.props || [];
    const title = (
      <div>
        <h2>
          <span style-="color: Black">
            <i className="fa fa-map" />
          </span>{' '}
          Manage Courses
        </h2>
      </div>
    );

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                stats={<small>Updated on {new Date().toLocaleDateString()}.</small>}
                title={title}
                category="View existing Courses, and create new Courses."
                content={
                  <Row>
                    <Col md={8}>
                      <CourseList
                        courses={courses}
                        removeCourse={course => this.props.dispatch(removeCourse(course))}
                      />
                    </Col>
                    <Col md={4}>
                      <AddCourse
                        addCourse={course => {
                          this.props.dispatch(addCourse(course));
                        }}
                      />
                    </Col>
                  </Row>
                }
              ></Card>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const admin = state.admin;
  return admin;
};
export default connect(mapStateToProps)(ManageCourses);
