import ApprovedRiders from './components/ApprovedRiders';
import Courses from './components/Courses';
import ManageCourses from './components/ManageCourses';
import ManageRiders from './components/ManageRiders';
import ManageSchedule from './components/ManageSchedule';
import ManageTeams from './components/ManageTeams';
import Race from './components/Race';
import Schedule from './components/Schedule';
import Teams from './components/Teams';

const routes = [
  {
    admin: false,
    path: '/schedule',
    name: 'Schedule',
    icon: 'pe-7s-date',
    component: Schedule,
  },
  // {
  //   admin: false,
  //   collapse: true,
  //   path: '/standings',
  //   name: 'Standings',
  //   state: 'openComponents',
  //   icon: 'pe-7s-cup',
  //   views: [
  //     {
  //       path: '/standings/agroup',
  //       name: 'A-Group',
  //       mini: 'A',
  //       component: Schedule,
  //     },
  //     {
  //       path: '/standings/bgroup',
  //       name: 'B-Group',
  //       mini: 'B',
  //       component: Schedule,
  //     },
  //   ],
  // },
  {
    admin: false,
    path: '/approved-riders',
    name: 'Approved Riders',
    icon: 'pe-7s-like2',
    component: ApprovedRiders,
  },
  // {
  //   admin: false,
  //   path: '/teams',
  //   name: 'Teams',
  //   icon: 'pe-7s-bicycle',
  //   component: Teams,
  // },
  // {
  //   admin: false,
  //   path: '/courses',
  //   name: 'Courses',
  //   icon: 'pe-7s-map',
  //   component: Courses,
  // },
  {
    admin: false,
    path: '/races',
    name: 'Races',
    component: Race,
    icon: 'pe-7s-bicycle',
    hideInSidebar: true,
  },
  {
    admin: true,
    collapse: true,
    path: '/admin',
    name: 'Race Director',
    icon: 'pe-7s-speaker',
    views: [
      {
        path: '/admin/schedule',
        name: 'Manage Schedule',
        mini: 'S',
        component: ManageSchedule,
      },
      {
        path: '/admin/courses',
        name: 'Manage Courses',
        mini: 'C',
        component: ManageCourses,
      },
      {
        path: '/admin/teams',
        name: 'Manage Teams',
        mini: 'T',
        component: ManageTeams,
      },
      {
        path: '/admin/riders',
        name: 'Manage Riders',
        mini: 'R',
        component: ManageRiders,
      },
    ],
  },
  { admin: false, redirect: true, path: '/', pathTo: '/schedule', name: 'Schedule' },
];
export default routes;
