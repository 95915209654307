import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { version } from '../package.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';

import AuthContainer from './containers/Auth';
import Container from './containers/Container';

console.log(`GVCC's GTour App:${version}`);

class App extends Component {
  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/auth" component={AuthContainer} />
            <Route path="/" component={Container} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
