export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Admin Team Actions
export const ADD_TEAM = 'ADD_TEAM';
export const GET_TEAMS = 'GET_TEAMS';
export const REMOVE_TEAM = 'REMOVE_TEAM';

// Admin Course Actions
export const ADD_COURSE = 'ADD_COURSE';
export const GET_COURSES = 'GET_COURSES';
export const REMOVE_COURSE = 'REMOVE_COURSE';

// Admin Race Actions
export const ADD_RACE = 'ADD_RACE';
export const GET_RACES = 'GET_RACES';
export const REMOVE_RACE = 'REMOVE_RACE';

// User Race Actions
export const ADD_MARSHALL = 'ADD_MARSHALL';
export const REMOVE_MARSHALL = 'REMOVE_MARSHALL';
