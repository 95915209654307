import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Card from '../../Card/Card';
import Button from '../../CustomButton/';

export default class TeamList extends Component {
  render() {
    const headers = ['Team Name'];
    const teams = this.props.teams || [];
    const TeamTable = (
      <div>
        <Table striped hover responsive className="smushed">
          <thead>
            <tr>
              {headers.map((prop, key) => {
                return <th key={key}>{prop}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {teams.map((team, key) => {
              return (
                <tr key={key}>
                  <td>{team.name}</td>
                  <td>
                    <Button
                      className="pull-right btn-simple"
                      bsStyle="danger"
                      onClick={() => {
                        this.props.removeTeam(team);
                      }}
                    >
                      <i className="fa fa-trash fa-lg"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
    return <Card title="Manage Teams" content={TeamTable} />;
  }
}
